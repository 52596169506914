import useAuth from "../Hooks/useAuth";
import { campaignRequest, dataRequest, campaignManagerRequest } from "../utils/authConfig";

const DataApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    GetTopCountries: async (companyId, accountId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Country`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting top countries. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting top countries.");
      }
    },
    GetAccountMessage: async (companyId, accountId, period) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Messages/${period}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting account message. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting account message.");
      }
    },
    GetAccountStatus: async (accountId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Status`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting account message. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting account message.");
      }
    },
    GetAccountReachByMotnh: async (companyId, accountId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Sms`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the reach by month. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the reach by month.");
      }
    },
    GetCampiagnsAccount: async (companyId, accountId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Campaigns`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting campaigns account. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting campaigns account.");
      }
    }, 
    getCampaignHistoryByCompany: async (companyId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              'Authorization': bearer
            },
            body: JSON.stringify({
              Channel: null,
              CampaignName: null,
              CampaignDate: null
            }),
          };
          const resource = `/api/History/Company/${companyId}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the campaigns. Status Code: ${response.status}`);
          }
    
          let result = await response.json(); 
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the history of camagns by company.");
      }
    },
    getCampaignHistoryByAccounts: async (accounts) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              'Authorization': bearer
            },
            body: JSON.stringify({
              Channel: null,
              CampaignName: null,
              CampaignDate: null,
              Accounts: accounts
            }),
          };
          const resource = `/api/History/Account`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the campaigns. Status Code: ${response.status}`);
          }
    
          let result = await response.json(); 
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the history of camagns by company.");
      }
    },
    getCampaignDetails: async (companyId, campaignId, channelId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Detail/${companyId}/Campaign/${campaignId}/Channel/${channelId}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the campaign details. Status Code: ${response.status}`);
          }
    
          let reports = await response.json();
          return reports;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the history of camagns by company.");
      }
    },
    getBilling: async (channelId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Dashboard/Billing/${channelId}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the billing. Status Code: ${response.status}`);
          }
    
          let data = await response.json();
          return data;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the billing.");
      }
    },
    getConsumption: async (channel, userType, time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/Spend/${channel}/${userType}/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getTotalCampaigns: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/TotalCampaigns/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getCampaignsTopCountries: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/TopCountries/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getConsumptionCompanies: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/ExpensesCompanies/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getConsumptionTopCompanies: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/TopCompanies/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getExpensesChannel: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/ExpensesChannel/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getBalanceCompany: async (id, companyId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              'Authorization': bearer
            },
            body: JSON.stringify({CompanyId: companyId})
          };
          const resource = `/api/Dashboard/Balance/${id}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the balance. Status Code: ${response.status}`);
          }
    
          let data = await response.json();
          return data;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the balance.");
      }
    },
    getBalanceAccount: async (id, accountId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              'Authorization': bearer
            },
            body: JSON.stringify({AccountId: accountId})
          };
          const resource = `/api/Dashboard/Balance/${id}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the balance. Status Code: ${response.status}`);
          }
    
          let data = await response.json();
          return data;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the balance.");
      }
    },
    getTotalCampaigns: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              'Authorization': bearer
            },
            body: JSON.stringify(data)
          };
          
          const resource = `/api/Dashboard/TotalCampaigns/Time/${time}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting total campaigns. Status Code: ${response.status}`);
          }
    
          let responseData = await response.json();
          return responseData;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting  total campaigns.");
      }
    },
    getTotalCampaignsByUser: async (userType, time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              'Authorization': bearer
            },
            body: JSON.stringify(data)
          };
          const resource = `/api/Dashboard/TotalCampaigns/User/${userType}/Time/${time}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting total campaigns. Status Code: ${response.status}`);
          }
    
          let responseData = await response.json();
          return responseData;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting  total campaigns.");
      }
    },
    getLastCampaigns: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/LastCampaigns/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getSmsStatus: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/StatusSMS/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getVoiceStatus: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/StatusVoice/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getCostAccount: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/CostAccount/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getCostAccount: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/CostAccount/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getExpensesChannelDate: async (time, data) => {
      const accessToken = await passTokenToApi(dataRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(data)
        };
        const resource = `/api/Dashboard/ExpensesChannelDate/Time/${time}`;
        const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;

        let response = await fetch(url, options);
        let responseData = await response.json();
        return responseData;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },  
    GetAccountNumbersCompany: async (companyId) => {
      const accessToken = await passTokenToApi(dataRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Numbers/Company/${companyId}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting top countries. Status Code: ${response.status}`);
          }
          console.log(response)
          let numberCampaigns = await response.json();
          return numberCampaigns;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting top countries.");
      }
    },
    cancelCampaign: async ( campaignId )=>{
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();

          const bearer = `Bearer ${accessToken}`;
          myHeaders.append("Authorization", bearer);

          var requestOptions = {
            method: 'PUT',
            headers: myHeaders
          };

          const resource = `/api/Campaign/Cancel/${campaignId}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
          
          if (response.status !== 200) {
            let text;
            let message;
            try {
                text = await response.text();
                const messagejson = JSON.parse(text);
                message = messagejson.message;
            } catch (error) {
                message = text;
            }
            throw new Error(message);
          }

          return response;

        }else{
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    cancelCampaignManager: async ( campaignId )=>{
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();

          const bearer = `Bearer ${accessToken}`;
          myHeaders.append("Authorization", bearer);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders
          };

          const resource = `/api/Campaign/Cancel/${campaignId}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
          
          if (response.status !== 200) {
            let text;
            let message;
            try {
                text = await response.text();
                const messagejson = JSON.parse(text);
                message = messagejson.message;
            } catch (error) {
                message = text;
            }
            throw new Error(message);
          }

          return response;

        }else{
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    }
  }
}

export default DataApi;


