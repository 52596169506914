import { Menu, Spin, Alert  } from "antd";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuditOutlined, ShopOutlined } from "@ant-design/icons";
import { 
  faClipboardList, faShoppingBag, faListUl, faChartSimple, faBuilding, faAddressBook, faFolderTree, 
  faUsers, faUserPlus, faList, faMessage, faUserGroup, faCopy, faLink, faCode, faFile, faVoicemail, faHammer 
} from "@fortawesome/free-solid-svg-icons";
import { faClipboardListCheck, faStore, faFileChartLine, faFileChartColumn, faGearCode, faMessageImage } from "@fortawesome/pro-solid-svg-icons";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";

const MenuCustom = ({ data }) => {
  const [activeKeys, setActiveKeys] = useState({ itemKey: null, submenuKey: [] });
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga del menú
  const { t } = useTranslation("menu");

  const menuNameIconMapping = {
    "Performance": faChartSimple,
    "Company Home": faBuilding,
    "Create Campaign": faStore,
    "Campaigns History": faClipboardList,
    "Reports": faFileChartLine,
    "Contacts": faAddressBook,
    "Contactos": faAddressBook,
    "Templates": faFolderTree,
    "Plantillas": faFolderTree,
    "Accounts": faUsers,
    "Users": faUserPlus,
    "Rates": faChartSimple,
    "Balance": faListUl,
    "Balance History": faFileChartLine,
    "Settings": faBuilding,
    "Webhook URL": faFile,
    "SMS": faMessage,
    "Bulk SMS": faMessage,
    "Shortlink": faLink,
    "Shortlinks Metrics": faFileChartLine,
    "Voice": faVoicemail,
    "Shortlinks Metrics": faFileChartLine,
    "Shortlinks Report": faFileChartLine,
    "Bulk Voice": faVoicemail,
    "Voice Bulk": faVoicemail,
    "Groups": faList,
    "Create": faClipboardList,
    "Listas": faList,
    "Crear": faClipboardList,
    "History": faCopy,
    "Historial": faCopy,
    "API's": faHammer,
    "Documentation": faFile,
    "Documentacion": faFile,
    "Companies": faBuilding,
    "Add Channel": faFolderTree,
    "WhatsApp Business": faWhatsappSquare,
    "WhatsApp Templates": faWhatsappSquare,
    "WhatsApp": faWhatsappSquare,
    "Bulk WhatsApp": faWhatsappSquare,
    "RCS": faMessageImage,
    "Bulk RCS": faMessageImage,
  };

  const findMenuItemByPath = (menuData, path) => {
    const traverseMenu = (items, parentKeys = []) => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const currentKey = parentKeys.concat(i);

        if (item.link === path) {
          return {
            itemKey: `menu-item-${currentKey.join('-')}`,
            submenuKey: parentKeys.map((key, index) => `menu-item-${parentKeys.slice(0, index + 1).join('-')}`)
          };
        }

        if (item.children) {
          const foundChild = traverseMenu(item.children, currentKey);
          if (foundChild) {
            return foundChild;
          }
        }
      }
      return null;
    };

    return traverseMenu(menuData);
  };

  const handleMenuSelect = ({ key, keyPath }) => {
    setActiveKeys({ itemKey: key, submenuKey: keyPath.slice(1).reverse() });
  };

  useEffect(() => {
    const path = location.pathname;
    console.log("Current pathname:", path);
    const foundItem = findMenuItemByPath(data, path);
    if (foundItem) {
      const { itemKey, submenuKey } = foundItem;
      console.log(`Setting active keys: itemKey=${itemKey}, submenuKey=${submenuKey}`);
      setActiveKeys({ itemKey, submenuKey });
    } else {
      console.log("No active menu item found for the current path.");
    }
  }, [location.pathname, data]);

  useEffect(() => {
    setLoading(true); // Inicia la carga del menú
    // Simula una carga de datos (aquí llamo el API o utilizo data directamente)
    setTimeout(() => {
      setLoading(false); // Finaliza la carga del menú
    }, 2000); // Simula una carga de 2 segundos
  }, []);


  const renderMenu = (item, parentIndex) => {
    const menuItemId = `menu-item-${parentIndex}`;
    //Obtiene el nombre del ícono de acuerdo al texto del menu
    const iconName = menuNameIconMapping[item.menuName];
    //obtiene el icono Fntawesome correspondiente
    const icon = iconName ? eval(iconName) : null;
    //Agrega clase basada de acuerdo al texto del menu
    const menuClass = item.menuName.toLowerCase().replace(/\s/g, '-');

    if (item.children && item.children.length > 0) {
      return (
        <Menu.SubMenu
          key={menuItemId}
          title={
            <>
              {icon && <FontAwesomeIcon icon={icon} />}
              <span>{t(item.menuName)}</span>
            </>
          }
          id={menuItemId}
          className={menuClass}
        >
          {item.children.map((child, idx) => renderMenu(child, `${parentIndex}-${idx}`))}
        </Menu.SubMenu>
      );
    } else {
      return (
        <Menu.Item key={menuItemId} id={menuItemId} className={menuClass}>
          {icon && <FontAwesomeIcon icon={icon} />} {/*Renderiza el icono*/}
          <Link to={item.link}>
            {t(item.menuName)}
          </Link>
        </Menu.Item>
      );
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Spin size="large" />        
      </div>
    );
  }

  // Maneja el caso cuando data está vacío
  if (!data || data.length === 0) {
    return <div style={{ textAlign: "center", margin: "5px" }}>
      <p>{t("data-menu.error-data-menu")}</p>
    </div>; // Mensaje para cuando no hay datos
  }
  return (
    <Menu
      mode="inline"
      selectedKeys={[activeKeys.itemKey]}
      defaultOpenKeys={activeKeys.submenuKey}
      onSelect={handleMenuSelect}
    >      
      {data.map((item, index) => renderMenu(item, index.toString()))}
    </Menu>
  );
};

export default MenuCustom;
