import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { currencyFormat, getMonthByDate } from "../../../../utils/helpers";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./AssignedCredit.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { Spin } from "antd";
import { CONSTANTUSERTYPE } from "../../../../utils/const";
import DataApi from "../../../../Api/DataApi";

const AssignedCredit = ({filter}) => {

  const { t, i18n }  = useTranslation("home-account");
  const {getBalanceAccount} = DataApi();

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if(filter && filter.id) {
      getInitialData(filter.id)
        .then(response => {
          const {assignedCreditValue} = response;
          setValue(assignedCreditValue);
          setLoading(false);
        })
        .catch(error => {
          console.error(error);
          setLoading(false);
        })
    }
  }, [filter])

  const getInitialData = async (accountId) => {
    let response = await getBalanceAccount(CONSTANTUSERTYPE.ACCOUNT.key, accountId);
    return { assignedCreditValue: response.totalBalance }
  }

  return (
    <div className={styles['dashboard-header-spinner']}>
      <Spin spinning={loading}>
        <div className={styles['dashboard-header-card']}>
          <div className={styles['dashboard-header-card-title']}>
            <p>{t("assigned-credit.title")}</p>
            <HelperButton
              message={t("assigned-credit.tootltip-title")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <div className={styles['dashboard-header-card-value']}>
            <p>{currencyFormat(value)} USD</p>
          </div>
          <div className={styles['dashboard-header-card-footer']}>
          <p>{getMonthByDate(new Date(), i18n.language)}</p>
          </div>      
        </div>
      </Spin>
    </div>
  );
}
 
export default AssignedCredit;