import { useEffect, useState } from 'react';

const useFacebookLogin = () => {
  const [user, setUser] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(true)

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : process.env.REACT_APP_WA_APP_ID,
        cookie     : true,
        xfbml      : true,
        version    : process.env.REACT_APP_WA_SDK_VERSION
      });
      window.FB.AppEvents.logPageView();
    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const handleFacebookLogin = () => {
    setButtonEnabled(false)
    window.FB.login(function(response) {
      if (response.authResponse) {
        const code = response.authResponse.code;
        setUser(code)
        setButtonEnabled(true)
      } else {
        if (response.status === 'not_authorized' || response.status === 'unknown') {
          setUser("_error_")
        } else if (response.error && response.error.code === 4) {
          // Error code 4 corresponds to rate limit exceeded
          console.error('Rate limit exceeded', response.error.message)
          setUser("_rate_limit_exceeded_")
        } else {
          setUser("_error_")
        }
      }
      setButtonEnabled(true)
    }, {
        config_id: process.env.REACT_APP_WA_CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here. Data personal
          }
        }
      },
      {scope: 'public_profile,email'});
  };

  const handleFacebookLogout = () => {
    setUser(null)
  }

  return { user, buttonEnabled, handleFacebookLogin, handleFacebookLogout }
};

export default useFacebookLogin;