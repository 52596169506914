import { faHandPointRight, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Col, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import { GetBolbStorage, getTypeFile, isValidFileType, notificationError } from "../../../utils/helpers";
import ButtonYp from '../../Button/Button';
import HelperButton from "../../HelperButton/HelperButton";
import styles from "./UploadFile.module.css";
import BestPracticeButton from "../../BestPracticeButton/BestPracticeButton";

const { Dragger } = Upload;

const UploadFile = ({onUploadFile, onRemoveFile, defaultFile}) => {

  const [t] = useTranslation("create-contacts");

  const [ fileList, setFileList] = useState([]);
  const [ fileName, setFileName] = useState("");

  useEffect(() => {
    if(defaultFile) {
      setFileList([defaultFile])
      setFileName(defaultFile.name)
    } else {
      setFileList([])
      setFileName("");
    }
  }, [defaultFile, t])

  
  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileName("");
      onRemoveFile(null);
    },
    beforeUpload: file => {

      if(!isValidFileType(file.type)) {
          let text = t("upload-file.alert-no-csv");
          text = text.replace("{filename}", file.name);
          notificationError(text);
          return false;
      }

      setFileList([file]);
      setFileName(file.name);

      let fileType = getTypeFile(file.type);
      onUploadFile(file, fileType);
      return false;
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      if(!isValidFileType(file.type)) {
        let text = t("upload-file.alert-no-csv");
        text = text.replace("{filename}", file.name);
        notificationError(text);
      }
    },
    fileList,
    accept: ".csv,.xlsx"
  };
  
  const onDowloadTemplate = () => {
    GetBolbStorage('yp-bulk-campaign-template.csv')
      .then(response => {

        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(response);

        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = "yp-campaign-template.csv";

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        // Remove link from body
        document.body.removeChild(link);


      })
      .catch(error => {
        console.error(error);
      });
  };


  const onDowloadTemplateExcel = () => {
    GetBolbStorage('yp-bulk-campaign-template.xlsx')
      .then(response => {

        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(response);

        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = "yp-campaign-template.xlsx";

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        // Remove link from body
        document.body.removeChild(link);


      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <Row>
        <Col>
          <div className={styles['file-template']}>
            <ButtonYp
              type="link"
              onClick={onDowloadTemplate}
              icon={<FontAwesomeIcon icon={ faHandPointRight } />}
            >
              {t("upload-file.label-template-csv")}
            </ButtonYp>
            <HelperButton
              message={t("upload-file.tooltip-download")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <div className={styles['file-template']}>
            <ButtonYp
              type="link"
              onClick={onDowloadTemplateExcel}
              icon={<FontAwesomeIcon icon={ faHandPointRight } />}
            >
              {t("upload-file.label-template-excel")}
            </ButtonYp>
            <HelperButton
              message={t("upload-file.tooltip-download-excel")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <div className={styles['file-dragger']}>
            <Dragger {...props} style={{ width: 700}}>
              <Button
                className={styles['file-dragger-button']} shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="large"
              />
              <br />
              <br />
              <p className="ant-upload-text"><strong>{t("upload-file.label-drag-file-title")}</strong></p>
              <br />
              <p className="ant-upload-hint">
                {t("upload-file.label-drag-file-subtitle-one")}
              </p>
              <br />
              <br />
              <p className="ant-upload-text" style={{ fontWeight: "bold"}}>
                {fileName}
              </p>
            </Dragger>
            <HelperButton
              message={(<div>
                <p>{t("upload-file.tooltip-file-title")}</p>
                <ul>
                  <li>{t("upload-file.tooltip-file-list-one")}</li>
                  <li>{t("upload-file.tooltip-file-list-two")}</li>
                  <li>{t("upload-file.tooltip-file-list-three")}</li>
                  <li>{t("upload-file.tooltip-file-list-four")}</li>
                </ul>  
              </div>)}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <BestPracticeButton />
        </Col>
      </Row>
      <Row style={{width: "100%", marginTop: 30}}>
        <Col>
          <div className={styles['alert-rows']}>
            <Alert type="error" message={t("upload-file.alert-rows")} banner />
          </div>
        </Col>
      </Row>
    </>
  );
}
 
export default UploadFile;