import styles from "./RatesHeaderOption.module.css";
import { Select, Input } from 'antd';
import useHeaderOptions from "../../../Hooks/useHeaderOptions";
import TYPES from "../../../reducers/types";
import { useState, useEffect } from "react";

const { Option } = Select;
const { Search } = Input;

const RatesHeaderOption = () => {

  const { headerOptionsState, headerOptionsDispacth } = useHeaderOptions();

  const [ defaultSelected , setDefaultSelected ] = useState(null);
  const [ countries , setCountries ] = useState([]);

  useEffect(() => {
    if(headerOptionsState.model.countries) {
      setCountries(headerOptionsState.model.countries);
    }
  }, [headerOptionsState])

  const handleCountryChange = (country) => {
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.SELECT_COMPANY_RATES_CHANGES_MODEL,
      payload: { selectedCountry: country }
    })
  };

  const onSearch = (e) => {
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.SELECT_COMPANY_RATES_CHANGES_MODEL,
      payload: { searchNetwork: e.target.value }
    })
  };

  return ( 
    <div className={styles['filters']}>
      <p>Country</p>
      <Select
        style={{width: 250}}
        className={styles['country-select']}
        placeholder="Please select a country"
        onChange={handleCountryChange}
        defaultValue={defaultSelected}
      >
        {
          countries.map(item =>
            <Option key={item} value={item}>{item}</Option>
          )
        }
      </Select>
      {/* <p>Network</p>
      <Search 
        className={styles['network-search']} 
        placeholder="Search by network" 
        onChange={onSearch} style={{ width: 200 }} 
      /> */}
    </div>
  );
}
 
export default RatesHeaderOption;