import { Select, Input } from 'antd';
import { useEffect, useState } from 'react';
import useHeaderOptions from '../../../Hooks/useHeaderOptions'
import useRatesAccount from '../../../Hooks/useRatesAccount'
import TYPES from '../../../reducers/types'
import Button from '../../Button/Button';
import styles from "./RatesByCompanyHeaderOption.module.css";
import CONST from "../../../utils/const"

const { Option } = Select;
const { Search } = Input;

const RatesByCompanyHeaderOption = () => {

  const { headerOptionsState, headerOptionsDispacth } = useHeaderOptions();  
  const { ratesAccountDispacth } = useRatesAccount();

  const [ defaultSelected , setDefaultSelected ] = useState(null);
  const [ countries , setCountries ] = useState([]);
  const [ disableButton, setDisableButton ] = useState(true);
  
  useEffect(() => {
    if(headerOptionsState.model.countries) {
      setCountries(headerOptionsState.model.countries);
      setDisableButton(!headerOptionsState.model.hasSelectedRows);
    }
  }, [headerOptionsState])

  const handleCountryChange = (country) => {
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_MODEL,
      payload: { selectedCountry: country }
    })
  };

  const onSearch = (e) => {
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_MODEL,
      payload: { searchNetwork: e.target.value }
    })
  };

  const nextStep = () => {
    ratesAccountDispacth({
      type: TYPES.RATES_PROCESS_STEP.SELECT_STEP,
      payload: CONST.RATES_PROCESS_STEP.SAVE_SELECT_RATES
    })
  }

  return (
    <div className={styles['filters']}>
      <p>Country</p>
      <Select
        style={{width: 250}}
        className={styles['country-select']}
        placeholder="Please select a country"
        onChange={handleCountryChange}
        defaultValue={defaultSelected}
      >
        {
          countries.map(item =>
            <Option key={item} value={item}>{item}</Option>
          )
        }
      </Select>
      {/* <p>Network</p>
      <Search 
        className={styles['network-search']} 
        placeholder="Search by network" 
        onChange={onSearch} style={{ width: 200 }} 
      /> */}
      <Button disabled={disableButton} type="primary" onClick={() => nextStep()}>
        Change Rates
      </Button>
    </div>
  );
}
 
export default RatesByCompanyHeaderOption;