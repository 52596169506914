import { Row, Col } from 'antd';
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { notificationWarning } from '../../../../utils/helpers';
import PhaseSchedule from './PhaseSchedule/PhaseSchedule';

const phaseInitialData = [{
  Id: 1,
  Percentage: 0,
  DateScheduled: null,
  LastDate: null
}];

const PhasesSchedule = ({onChangePhases}) => {

  const [t] = useTranslation("whatsapp-campaign");

  const [ phasesData, setPhasesData ] = useState(phaseInitialData);
  const [ percentage, setPercentage ] = useState(0);

  const onAddPhase = () => {

    if(percentage < 100) {
      let size = phasesData.length;
      let lastId = phasesData[size - 1].Id;

      const newPhase = {
        Id: lastId + 1,
        Percentage: null,
        DateScheduled: null,
        LastDate: null
      }
      let phases = [...phasesData, newPhase];
      setPhasesData(phases);
    } else {
      notificationWarning(t("schedule.notification-phase"));
    }
  }

  const onChangeSchedule = ({id, date, percentage}) => {
    let newData = phasesData.filter(x => x.Id !== id);

    newData.push({
      Id: id,
      Percentage: percentage,
      DateScheduled: date
    });

    newData = newData.sort((a, b) => {
      if (a.Id > b.Id) {
        return 1;
      }
      if (a.Id < b.Id) {
        return -1;
      }
      return 0;
    });

    let sumPercentage = 0;
    newData.forEach(x => {
      sumPercentage += x.Percentage ? x.Percentage : 0;
    });

    setPhasesData(newData);
    setPercentage(sumPercentage);

    if(onChangePhases) {
      onChangePhases(newData);
    }
  }

  return (
    <Row gutter={16} justify="center">
      <Col>
        {
          phasesData.map(phase => (
            <PhaseSchedule
              key={phase.Id}
              id={phase.Id}
              lastDate={phase.LastDate}
              onAddPhase={onAddPhase}
              onChangeSchedule={onChangeSchedule}
            />
          ))
        }
      </Col>
    </Row>
  );
}
 
export default PhasesSchedule;