import { Breadcrumb } from "antd";
import { Link, useLocation } from 'react-router-dom';
import { orangeColor } from "../../utils/colorsCss";
import useSteper from "../../Hooks/useSteper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const Breadcrum = ({data}) => {

  const [paths, setPaths] = useState([])

  useEffect(() => {
    if(data) {
      let result = data.map(x => x.path)
    }
  }, [data])

  const { breadcrumbState , breadcrumbDispacth} = useSteper();

  const [tr] = useTranslation("campaign-bulk"); 

  const breadcrumbNameMap = {
    '/Admin': 'Admin',
    '/Developers': 'API´s',
    '/Developers/apikeys':'API Key',
    '/Developers/apikeys/chanel/3':'',
    '/Developers/documentation':`${tr("documents.documents-main")}`,
    '/Admin/Companies': 'Companies',
    '/Companies': `${tr("companies.companies-main")}`,
    '/Company': `${tr("company.companyhome")}`,
    '/Company/Accounts': `${tr("company.companyhome-accounts")}`,
    '/Company/Users': `${tr("company.companyhome-users")}`,
    '/Company/Rates': `${tr("company.companyhome-rates")}`,
    '/Company/Balance': `${tr("company.companyhome-balance")}`,
    '/Company/HistoryBalance': `${tr("company.companyhome-balance-history")}`,
    '/HistoryBalance': `${tr("company.companyhome-balance-history")}`,
    '/Company/Settings': `${tr("company.companyhome-settings")}`,
    '/Company/WhatsAppBusiness': `${tr("company.companyhome-whatsapp-business")}`,
    '/Company/Settings/Webhook': 'Webhook',
    '/Campaigns': `${tr("campaings.campaings-a")}`,
    '/Campaigns/BulkCampaign': `${tr("campaings.campaings-creation")}  / ` + breadcrumbState.step,
    '/Campaigns/ShortLinks': `${tr("campaings.campaings-shorlinks")} / ` + breadcrumbState.step,
    '/Campaigns/ShortlinksReport': `${tr("campaings.campaings-shorlinks-report")}`, 
    '/Campaigns/Voz': `${tr("campaings.campaings-creation-voice")} / ` + breadcrumbState.step, 
    '/Campaigns/RCS': `${tr("campaings.campaings-creation-RCS")}`, 
    '/Campaigns/Whatsapp':  `${tr("campaings.campaings-creation-whatsapp")}`,
    '/Campaigns/Reports': `${tr("campaings.campaings-reports")}`,
    '/Campaigns/History': `${tr("campaings.campaings-history")}`,
    '/Account': 'Account',
    '/Contacts/Contacts': 'Contacts',
    '/Contacts': `${tr("contacts.contacts-main")}`,
    '/Contacts/History': `${tr("contacts.contacts-groups")}`,
    '/Contacts/Create': `${tr("contacts.contacts-create")} / ` + breadcrumbState.step, 
    '/Contacts/Groups': 'Groups',    
    '/Contacts/Filters': 'Filters',
    '/Templates':  `${tr("templates.templates-main")}`,
    '/Templates/History': `${tr("templates.templates-history")}`,
    '/WhatsAppTemplates': `${tr("whatsapp-templates.title")}`,
    '/WhatsAppTemplates/List': `${tr("whatsapp-templates.list")}`,
    '/WhatsAppTemplates/Create': `${tr("whatsapp-templates.create")}`,
  };

  const location = useLocation();

  const pathSnippets = location.pathname.split('/').filter(i => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    let style=null;
    if(index === pathSnippets.length-1){
      style={color: orangeColor};
    }

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}><span style={style} >{breadcrumbNameMap[url]}</span></Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Yellow Push</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumb className='breadcrumb' >
      {breadcrumbItems}
    </Breadcrumb>
  );
}
 
export default Breadcrum;