import { Upload, Button, Row, Col, Alert, Select } from 'antd';
import { useState, Fragment, useEffect } from 'react';
import styles from "./UploadFile.module.css";
import { getTypeFile, notificationError } from '../../../utils/helpers'
import { faUpload, faHandPointRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GetBolbStorage, isValidFileType } from '../../../utils/helpers'
import ButtonYp from '../../Button/Button';
import HelperButton from '../../HelperButton/HelperButton';
import { useTranslation } from 'react-i18next'
import { getGradientColor, purpleColor } from '../../../utils/colorsCss'
import TemplateApi from '../../../Api/TemplatesApi';
import ConfigurationApi from '../../../Api/ConfigurationApi';
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from '../../../reducers/types';
import CONSTANTS from '../../../utils/const';
import useAuth from '../../../Hooks/useAuth';
import { WarningFilled } from '@ant-design/icons';

const { Dragger } = Upload;
const { Option } = Select;

const UploadFile = ({accountId, defaultFile, defaultContac, onUploadFile, onRemoveFile, onChengeContact, messageData}) => {

  const [t] = useTranslation("whatsapp-campaign");

  const [ fileList, setFileList] = useState([]);
  const [ contacts, setContacts] = useState([]);
  const [ defaultContactSelected, setDefaultContactSelected] = useState([]);
  const [ contactSelected, setContactSelected] = useState(null);
  const [ fileName, setFileName] = useState("");
  const [ templateFile, setTemplateFile ] = useState([]);

  const { auth } =  useAuth();
  const { getListGorupsByAccount } = TemplateApi();
  const { spinnerDispacth } = useSpinner();
  const { getTemplateCampaign } = ConfigurationApi();

  useEffect(() => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
    });

    getListGorupsByAccount(accountId)
    .then(response => {
      let result = response.map(x => { return { label: x.groupName, value: x.id } });
      setContacts(result);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
      });
    })
    .catch(error => {
      console.error(error)
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
      });
    })
  }, [])

  useEffect(() => {
    getTemplateCampaign()
      .then(response => {
        let file = response?.[0]?.link || "";
        setTemplateFile(file);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  useEffect(() => {
    if(defaultFile) {
      setFileList([defaultFile])
      setFileName(defaultFile.name)
    } else {
      setFileList([])
      setFileName("");
    }
  }, [defaultFile, t])

  useEffect(() => {
    if(defaultContac) {
      setContactSelected(defaultContac);
    } 
  }, [defaultContac])

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileName("");
      onRemoveFile(null);
    },
    beforeUpload: file => {

      if(!isValidFileType(file.type)) {
        let text = t("upload-file.alert-no-csv");
        text = text.replace("{filename}", file.name);
        notificationError(text);
        return false;
      }

      setFileList([file]);
      setFileName(file.name);
      setContactSelected(null);

      let fileType = getTypeFile(file.type);
      onUploadFile(file, fileType);
      return false;
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      if(!isValidFileType(file.type)) {
        let text = t("upload-file.alert-no-csv");
        text = text.replace("{filename}", file.name);
        notificationError(text);
      }
    },
    fileList,
    accept: ".csv,.xlsx"
  };

  const onDowloadTemplate = () => {
    const blobUrl = templateFile;
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = "yp-campaign-template.csv";

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  };

  const handlerContact = (value) => {
    setFileList([]);
    setFileName("");
    onRemoveFile(null);
    setContactSelected(value);
    onChengeContact(value);
  }

  return (
    <Fragment>
      <Row style={{width: "100%"}}>
        <Col>
          {t("upload-file.label-contacts")}
        </Col>
      </Row>
      <Row style={{width: "100%", marginTop: 10}} gutter={10}>
        <Col lg={24} xl={10}>
          <Select
            style={{ width: "100%" }}
            placeholder={t("upload-file.placeholder-contacts")}
            onChange={handlerContact}
            defaultValue={defaultContactSelected}
            value={contactSelected}
          >
            {
              contacts.map(item =>
                <Option key={item.value} value={item.value}>{item.label}</Option>
              )
            }
          </Select>
          <div style={{ display: "flex", border: "1px solid #b9a7b9", color: "#b9a7b9", padding: "5px 12px", gap: "5px", borderRadius: "10px", marginTop: "10px" }}>
            <div>
              <WarningFilled />
            </div>
            <div>
              <p>{t("upload-file.sent-messages")}: {messageData?.sentMessages === 0 ? 0 : messageData?.sentMessages || "--"}</p>
              <p>{t("upload-file.remaining-messages")}: {messageData?.limit === -1 ? t("upload-file.unlimited") : messageData?.limit - (messageData?.sentMessages || 0)}</p>
              <p>{t("upload-file.message-limit")}: {messageData?.limit === -1 ? t("upload-file.unlimited") : messageData?.limit === 0 ? 250 : `${messageData?.limit} ${t("upload-file.customers24h")}` }</p>
            </div>
          </div>
        </Col>
        <Col lg={24} xl={14}>
          <div className={styles['file-template']}>
            <ButtonYp
              type="link"
              onClick={onDowloadTemplate}
              icon={<FontAwesomeIcon icon={ faHandPointRight } />}
            >
              {t("upload-file.label-template-csv")}
            </ButtonYp>
            <HelperButton
              message={t("upload-file.tooltip-download")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <div className={styles['file-dragger']}>
            <Dragger {...props}>
              <Button
                className={styles['file-dragger-button']} shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="large"
              />
              <p className="ant-upload-hint" style={{ marginBottom: "5px" }}>
                {t("upload-file.label-drag-file-subtitle-one")}
              </p>
              <p className="ant-upload-text" style={{ fontWeight: "bold"}}>
                {fileName}
              </p>
            </Dragger>
            <HelperButton
              message={(<div>
                <p>{t("upload-file.tooltip-file-title")}</p>
                <ul>
                  <li>{t("upload-file.tooltip-file-list-one")}</li>
                  <li>{t("upload-file.tooltip-file-list-two")}</li>
                  <li>{t("upload-file.tooltip-file-list-three")}</li>
                  <li>{t("upload-file.tooltip-file-list-four")}</li>
                </ul>  
              </div>)}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <Row style={{width: "100%", marginTop: 30}}>
            <Col>
              <div className={styles['alert-rows']}>
                <Alert type="error" message={t("upload-file.alert-file-rows")} banner />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default UploadFile;