import React, { useState } from "react";
import EditWhatsAppTemplate from "./Edit/Edit";
import WhatsAppTemplatesList from "./List/WhatsAppTemplates";

const WhatsAppTemplates = () => {
  const [idToEdit, setIdToEdit] = useState(null)

  return idToEdit ? <EditWhatsAppTemplate idToEdit={idToEdit} setIdToEdit={(id) => setIdToEdit(id)} /> : <WhatsAppTemplatesList setIdToEdit={(id) => setIdToEdit(id)} /> 
}

export default WhatsAppTemplates;