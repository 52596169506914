import React, { Fragment } from "react";

const RCSChannel = () => {
  return (
    <Fragment>
      <div className="yp-workspace">
        <div style={{ display: "flex" }}>
          Coming soon
        </div>
      </div>
    </Fragment>
  );
}
 
export default RCSChannel;