import { Col } from "antd";
import CardSummary from "./CardSummary/CardSummary";

const CountrySummary = ({summary}) => {
  return (
    <div style={{ width: "100%" }}>
      {
        summary.costDetails.map(detail => (
          <Col key={detail.countryName}>
            <CardSummary
              data={detail}
              campaignId={summary?.campaignId}
            />
          </Col>
        ))
      }
    </div>
  );
}
 
export default CountrySummary;