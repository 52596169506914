import TYPES from './types'

const initialCampaignState = {
  campaignName: "",
  account: null,
  accountYP: null,
  file: null,
  fileData: [],
  fileHeaders: [],
  fileRows: 0,
  loadContacts: false,
  contactSelected: null,
  headersMap: {
    mobileNumberColumn: null,
    customFields: [],
    selectedHeaders: []
  },
  senderId: "",
  message: {
    message: "",
    html: ""
  },
  wildcards: [],
  campaignId: null,
  scheduleData: null,
  summary: null,
  url: "",
  shortlink: "",
  template: null,
  formValues: null,
  messageData: null,
  bodyExample: null,
  bodyExampleFields: null,
  destination: null,
  campaignMobilePreviewData: null,
  bodyExampleQty: 0,
  formattedMessage: null,
  entriesInFile: null,
  selectedPhoneForTemplate: null,
  selectedDynamicFields: null,
}

const campaignReducer = (state, action) => {
  switch (action.type) {
    case TYPES.CAMPAIGN.ADD_CAMPAINGNAME:
      return {
        ...state,
        campaignName: action.payload
      }
    case TYPES.CAMPAIGN.ADD_ACCOUNT:
      return {
        ...state,
        account: action.payload
      }
    case TYPES.CAMPAIGN.ADD_ACCOUNT_YP:
      return {
        ...state,
        accountYP: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILE:
      return {
        ...state,
        file: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILEDATA:
      return {
        ...state,
        fileData: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILEHEADERS:
      return {
        ...state,
        fileHeaders: action.payload
      }
    case TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          mobileNumberColumn: action.payload
        }
      }
    case TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          customFields: action.payload
        }
      }
    case TYPES.CAMPAIGN.ADD_SELECTEDHEADERS:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          selectedHeaders: action.payload
        }
      }
    case TYPES.CAMPAIGN.ADD_MESSAGE:
      return {
        ...state,
        message: action.payload
      }
    case TYPES.CAMPAIGN.ADD_WILDCARDS:
      return {
        ...state,
        wildcards: action.payload
      }
    case TYPES.CAMPAIGN.ADD_SENDERID:
      return {
        ...state,
        senderId: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILEROWS:
      return {
        ...state,
        fileRows: action.payload
      }
    case TYPES.CAMPAIGN.ADD_CAMPAIGNID:
      return {
        ...state,
        campaignId: action.payload
      }
    case TYPES.CAMPAIGN.ADD_SCHEDULE:
      return {
        ...state,
        scheduleData: action.payload
      }
    case TYPES.CAMPAIGN.ADD_SUMMARY:
      return {
        ...state,
        summary: action.payload
      }
    case TYPES.CAMPAIGN.ADD_URL:
      return {
        ...state,
        url: action.payload
      }
    case TYPES.CAMPAIGN.ADD_SHORTLINK:
      return {
        ...state,
        shortlink: action.payload
      }
    case TYPES.CAMPAIGN.LOAD_CONTACTS:
      return {
        ...state,
        loadContacts: action.payload
      }
    case TYPES.CAMPAIGN.ADD_CONTACT:
      return {
        ...state,
        contactSelected: action.payload
      }
    case TYPES.CAMPAIGN.ADD_TEMPLATES:
      return {
        ...state,
        template: action.payload
      }
    case TYPES.CAMPAIGN.MESSAGE_DATA:
      return {
        ...state,
        messageData: action.payload
      }
    case TYPES.CAMPAIGN.BODY_EXAMPLE:
      return {
        ...state,
        bodyExample: action.payload
      }
    case TYPES.CAMPAIGN.BODY_EXAMPLE_FIELDS:
      return {
        ...state,
        bodyExampleFields: action.payload
      }
    case TYPES.CAMPAIGN.DESTINATION:
      return {
        ...state,
        destination: action.payload
      }
    case TYPES.CAMPAIGN.CAMPAIGN_MOBILE_PREVIEW_DATA:
      return {
        ...state,
        campaignMobilePreviewData: action.payload
      }
    case TYPES.CAMPAIGN.SELECT_TEMPLATE_BODYEXAMPLE:
      return {
        ...state,
        bodyExampleQty: action.payload
      }
    case TYPES.CAMPAIGN.SET_FORMATTED_MESSAGE:
      return {
        ...state,
        formattedMessage: action.payload
      }
    case TYPES.CAMPAIGN.FILE_ROWS_COUNT:
      return {
        ...state,
        entriesInFile: action.payload
      }
    case TYPES.CAMPAIGN.SELECTED_PHONE_FOR_WA_TEMPLATE:
      return {
        ...state,
        selectedPhoneForTemplate: action.payload
      }
    case TYPES.CAMPAIGN.SELECTED_DYNAMIC_FIELDS:
      return {
        ...state,
        selectedDynamicFields: action.payload
      }
    default:
      return state;
  }
}

export { initialCampaignState }
export default campaignReducer;