import { Upload, Button, Select, Card, Tabs, Spin, Tooltip } from 'antd';
import { useState, Fragment, useEffect, useReducer } from 'react';
import styles from "./ApprovedTemplates.module.css";
import { cleanErrorMessage, getTypeFile, notificationError } from '../../../utils/helpers'
import { isValidFileType } from '../../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { getGradientColor, purpleColor } from '../../../utils/colorsCss'
import TemplateApi from '../../../Api/TemplatesApi';
import ConfigurationApi from '../../../Api/ConfigurationApi';
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from '../../../reducers/types';
import CONSTANTS from '../../../utils/const';
import useAuth from '../../../Hooks/useAuth';
import WhatsappApi from '../../../Api/WhatsappApi';
import MobilePreview from '../../../pages/WhatsApp/WhatsAppTemplates/List/MobilePreview';
import { CheckCircleOutlined } from '@ant-design/icons';
import campaignReducer, { initialCampaignState } from '../../../reducers/campaignWhatsappReducer';


const ApprovedTemplates = ({wabaId, defaultFile, defaultContac, onUploadFile, onRemoveFile, onChengeContact, onTemplateChange, messageData, handleMessageData, handleCampaignMobilePreviewData, handleBodyExampleQty }) => {
  const [t] = useTranslation("whatsapp-campaign");
  const [ selected, setSelected] = useState(messageData?.wabaTemplateId || '');
  const [ fileList, setFileList] = useState([]);
  const [ templates, setTemplates] = useState({});
  const [ contactSelected, setContactSelected] = useState(null);
  const [ fileName, setFileName] = useState("");
  const [ templateFile, setTemplateFile ] = useState([]);
  const [previewData, setPreviewData] = useState(null)
  const [templateData, setTemplateData] = useState(null)

  const { auth } =  useAuth();
  const { getListGorupsByAccount } = TemplateApi();
  const { spinnerDispacth } = useSpinner();
  const { getTemplateCampaign } = ConfigurationApi();
  const { getApprovedTemplatesByWabaId, getTemplateById } = WhatsappApi();

  const restructureByStatus = (data) => {
    return data.reduce((acc, item) => {
      const { category } = item;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
  }

  useEffect(() => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.WABATEMPLATES }
    });

    getApprovedTemplatesByWabaId(wabaId)
      .then(response => {
        let result = restructureByStatus(response.data)
        setTemplates(result);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.WABATEMPLATES }
        });
      })
    .catch(error => {
      console.error(error)
      notificationError(cleanErrorMessage(error));
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.WABATEMPLATES }
      });
    })
  }, [])

  useEffect(() => {
    getTemplateCampaign()
    .then(response => {
      let file = response.link;
      setTemplateFile(file);
    })
    .catch(error => {
      console.log(error);
    })
  }, []);

  useEffect(() => {
    if (templateData) {
      handleMessageData({
        wabaTemplateId: templateData.wabaTemplateId,
        lastModifiedBy: templateData.lastModifiedBy
      })
      handleCampaignMobilePreviewData({ bodyText: templateData.bodyText })
    }
  }, [templateData]);

  useEffect(() => {
    if(defaultFile) {
      setFileList([defaultFile])
      setFileName(defaultFile.name)
    } else {
      setFileList([])
      setFileName("");
    }
  }, [defaultFile, t])

  useEffect(() => {
    if(defaultContac) {
      setContactSelected(defaultContac);
    } 
  }, [defaultContac])

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileName("");
      onRemoveFile(null);
    },
    beforeUpload: file => {

      if(!isValidFileType(file.type)) {
        let text = t("upload-file.alert-no-csv");
        text = text.replace("{filename}", file.name);
        notificationError(text);
        return false;
      }

      setFileList([file]);
      setFileName(file.name);
      setContactSelected(null);

      let fileType = getTypeFile(file.type);
      onUploadFile(file, fileType);
      return false;
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      if(!isValidFileType(file.type)) {
        let text = t("upload-file.alert-no-csv");
        text = text.replace("{filename}", file.name);
        notificationError(text);
      }
    },
    fileList,
    accept: ".csv,.xlsx"
  };

  const onDowloadTemplate = () => {
    const blobUrl = templateFile;
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = "yp-campaign-template.csv";

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  };

  const handlerContact = (value) => {
    setFileList([]);
    setFileName("");
    onRemoveFile(null);
    setContactSelected(value);
    onChengeContact(value);
  }

  return (
    <Fragment>
      <h3>{t("templates")}</h3>
      <Card size="small" title={t("categories")} style={{ width: "100%" }} className={styles["categories-card"]} headStyle={{ background: "#f2f2f2", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={t("utility")} key="utility" style={{ maxHeight: "300px", overflow: "scroll", minHeight: "300px", padding: "0px 3px" }}>
            {templates?.["UTILITY"]?.map(template => {
              return (
                <Tooltip
                  title={
                    <Fragment>
                      {previewData?.hasError ? <span>{t("error-getting-template")}</span> :
                        previewData?.loading ? <Spin spinning={true} /> : 
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                          {selected !== template.wabaTemplateId && <Button id='select-this-template-button' style={{ margin: "auto" }} onClick={() => {
                            const {bodyExample, ...v} = previewData?.data
                            setSelected(template.wabaTemplateId)
                            onTemplateChange(v)
                            setTemplateData(v)
                            handleBodyExampleQty(bodyExample.length)
                          }}>{t("select-this-template")}</Button>}
                          <MobilePreview previewValues={previewData?.data} />
                        </div>
                      }
                    </Fragment>
                  }
                  placement='rightTop'
                  color={getGradientColor(purpleColor)}
                  overlayInnerStyle={{padding: 20}}
                  onVisibleChange={async visible => {
                    try {
                      setPreviewData({ key: template.wabaTemplateId, loading: true, data: null })
                      if (visible) {
                        const templateById = await getTemplateById(template.wabaTemplateId)
                        setPreviewData({ key: template.wabaTemplateId, loading: false, data: templateById?.data })
                      } else {
                        setPreviewData({ key: template.wabaTemplateId, loading: false, data: null })
                      }
                    } catch (e) {
                      setPreviewData({ key: template.wabaTemplateId, loading: false, data: null, hasError: true })
                      notificationError(e || ".error-getting-template", 15)
                    }
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={selected === template.wabaTemplateId ? styles["selected-template"] : styles["template-item"]}>
                    <span>
                      {template.name}
                    </span>
                    {selected === template.wabaTemplateId && <CheckCircleOutlined />}
                  </div>
                </Tooltip>
              )
            })}
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("marketing")} key="marketing" style={{ maxHeight: "300px", overflow: "scroll", minHeight: "300px", padding: "0px 3px" }}>
            {templates?.["MARKETING"]?.map(template => {
              return (
                <Tooltip
                  title={
                    <Fragment>
                      {previewData?.hasError ? <span>{t("error-getting-template")}</span> :
                        previewData?.loading ? <Spin spinning={true} /> : 
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                          {selected !== template.wabaTemplateId && <Button id='select-this-template-button' style={{ margin: "auto" }} onClick={() => {
                            const {bodyExample, ...v} = previewData?.data
                            setSelected(template.wabaTemplateId)
                            onTemplateChange(v)
                            setTemplateData(v)
                            handleBodyExampleQty(bodyExample.length)
                          }}>{t("select-this-template")}</Button>}
                          <MobilePreview previewValues={previewData?.data} />
                        </div>
                      }
                    </Fragment>
                  }
                  placement='rightTop'
                  color={getGradientColor(purpleColor)}
                  overlayInnerStyle={{padding: 20}}
                  onVisibleChange={async visible => {
                    setPreviewData({ key: template.wabaTemplateId, loading: true, data: null })
                    if (visible) {
                      const templateById = await getTemplateById(template.wabaTemplateId)
                      setPreviewData({ key: template.wabaTemplateId, loading: false, data: templateById?.data })
                    } else {
                      setPreviewData({ key: template.wabaTemplateId, loading: false, data: null })
                    }
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={selected === template.wabaTemplateId ? styles["selected-template"] : styles["template-item"]}>
                    <span>
                      {template.name}
                    </span>
                    {selected === template.wabaTemplateId && <CheckCircleOutlined />}
                  </div>
                </Tooltip>
              )
            })}
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("authentication")} key="authentication" style={{ maxHeight: "300px", overflow: "scroll", minHeight: "300px", padding: "0px 3px" }}>
            {templates?.["AUTHENTICATION"]?.map(template => {
              return (
                <Tooltip
                  title={
                    <Fragment>
                      {previewData?.hasError ? <span>{t("error-getting-template")}</span> :
                        previewData?.loading ? <Spin spinning={true} /> : 
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                          {selected !== template.wabaTemplateId && <Button id='select-this-template-button' style={{ margin: "auto" }} onClick={() => {
                            const {bodyExample, ...v} = previewData?.data
                            setSelected(template.wabaTemplateId)
                            onTemplateChange(v)
                            setTemplateData(v)
                            handleBodyExampleQty(bodyExample.length)
                          }}>{t("select-this-template")}</Button>}
                          <MobilePreview previewValues={previewData?.data} />
                        </div>
                      }
                    </Fragment>
                  }
                  placement='rightTop'
                  color={getGradientColor(purpleColor)}
                  overlayInnerStyle={{padding: 20}}
                  onVisibleChange={async visible => {
                    setPreviewData({ key: template.wabaTemplateId, loading: true, data: null })
                    if (visible) {
                      const templateById = await getTemplateById(template.wabaTemplateId)
                      setPreviewData({ key: template.wabaTemplateId, loading: false, data: templateById?.data })
                    } else {
                      setPreviewData({ key: template.wabaTemplateId, loading: false, data: null })
                    }
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={selected === template.wabaTemplateId ? styles["selected-template"] : styles["template-item"]}>
                    <span>
                      {template.name}
                    </span>
                    {selected === template.wabaTemplateId && <CheckCircleOutlined />}
                  </div>
                </Tooltip>
              )
            })}
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Fragment>
  );
}

export default ApprovedTemplates;