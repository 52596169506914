import { notification } from 'antd';
import { faStarShooting, faBomb, faBellOn } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlobServiceClient } from "@azure/storage-blob";
import { notificationOkColor, notificationWarningColor, notificationErrorColor } from './colorsCss'
import CONSTANTS from './const';

export function truncateString(text, length) {
  return text ? text.substring(0, length) : text;
}

// Format number in currency
export function currencyFormat(value, minimumFractionDigits = 4) {
  const options = { style: 'currency', currency: 'USD', minimumFractionDigits: minimumFractionDigits };
  const numberFormat = new Intl.NumberFormat('en-US', options);
  return isNaN(value) ? "$N/A" : numberFormat.format(value);
}

export function numberFormat(value, minimumFractionDigits = 4) {
  const options = { currency: 'USD', minimumFractionDigits: minimumFractionDigits };
  const numberFormat = new Intl.NumberFormat('en-US', options);
  return isNaN(value) ? "$N/A" : numberFormat.format(value);
}

export function roundNumber(value, digitsToRound = 2) {
  return +(Math.round(value + "e+" + digitsToRound)  + "e-"  + digitsToRound);
}

/**
 * Formats a date and time with an optional time zone.
 *
 * @param {string} value - The string representing the date and time.
 * @param {boolean} [hasTime=false] - Indicates whether to include time information. Default is false.
 * @param {string} [timeZone=''] - The time zone to use for formatting e.g: 'UTC'. Default is an empty string, indicating local time.
 * @returns {string|null} The formatted date and time, or null if the value is not a valid date.
 */
export function dateFormat(value, hasTime = false, timeZone = '') {
  if(value) {
    let date = new Date(value);
    if(hasTime) {
      let options = timeZone ? {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
        timeZone: timeZone
      } : {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false
      };
      return isNaN(date) ? null : new Intl.DateTimeFormat('en-US', options).format(date);
    } else {
      return isNaN(date) ? null : date.toISOString().split('T')[0];
    }    
  }

  return "N/A";
}

/*export function getMonthByDate(value) {
  if(value) {
    const options = { month: "long" };
    return new Intl.DateTimeFormat("en-US", options).format(value);
  }

  return "N/A";
}*/

export function getMonthByDate(value, language = "en") {
  if (value) {
    const options = { month: "long" };
    return new Intl.DateTimeFormat(language, options).format(value);
  }
  return "N/A";
}

export function timeFormat(value) {
  if(value) {
    let number = Number(value);
    var h = Math.floor(number / 3600);
    var m = Math.floor(number % 3600 / 60);
    var s = Math.floor(number % 3600 % 60);
    var hours = h < 10 ? `0${h}` : `${h}`;
    var minutes = m < 10 ? `0${m}` : `${m}`;
    var seconds = h < 10 ? `0${s}` : `${s}`;
    return `${hours}:${minutes}:${seconds}`;
  }

  return "00:00:00";
}

export function filterByField(data, fieldName, value) {
  let result = data.filter(x => x[fieldName].toLowerCase().includes(value.toLowerCase()));
  return result;
}

export function notificationOk(message) {
  const args = {
    message: "TRANSACTION SUCCESSFUL",
    description: message,
    icon: <FontAwesomeIcon color={notificationOkColor.iconColor} className="fa-flip-both" icon={ faStarShooting } />,
    className: 'custom-class',
    duration: 7,
    style: {
      background: notificationOkColor.background,
      color: notificationOkColor.color
    },
    placement: "bottomLeft"
  };
  notification.open(args);
}

export function notificationWarning(message, duration = 7) {
  const args = {
    message: <p style={{color: notificationWarningColor.color}}>WARNING</p> ,
    description: message,
    icon: <FontAwesomeIcon color={notificationWarningColor.iconColor} icon={ faBellOn } />,
    className: 'custom-class',
    duration: duration,
    style: {
      background: notificationWarningColor.background,
      color: notificationWarningColor.color,
      width: 450
    },
    placement: "bottomLeft"
  };
  notification.open(args);
}

export function notificationError(message, duration = 7) {
  const args = {
    message: "ERROR",
    description: <div dangerouslySetInnerHTML={{ __html: message }} />,
    icon: <FontAwesomeIcon color={notificationErrorColor.iconColor} icon={ faBomb } />,
    className: 'custom-class',
    duration: duration,
    style: {
      background: notificationErrorColor.background,
      color: notificationErrorColor.color
    },
    placement: "bottomLeft"
  };
  notification.open(args);
}

export function GetTimeZoneById(id) {

  const timeZones = [{
    id: "B8A3523C-4F6E-4CE0-ADAA-344392D4E118",
    name: "Eastern Standard Time"
  }, {
    id: "7447D629-43F4-4600-B57C-D2EC478B6A20",
    name: "Central Standard Time"
  }]

  let timezone = timeZones.find(t => t.id === id);
  return timezone ? timezone.name : "";

}

export function GetCurrencyById(id) {

  const currencies = [{
    id: "922E3D0B-D2E2-4E97-840A-850B033B6E47",
    name: "USD"
  }, {
    id: "D8A212F8-86EA-4E6F-A75F-5E22A7FF6292",
    name: "EUR"
  }]

  let currency = currencies.find(t => t.id === id);
  return currency ? currency.name : "";

}

export async function GetBolbStorage(blobName) {

  const account = process.env.REACT_APP_AZURE_ACCOUNT;
  const sas = process.env.REACT_APP_AZURE_BLOB_SAS;
  const container = process.env.REACT_APP_AZURE_BLOB_CONTAINER;
  
  const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);

  // Get a reference to a container
  const containerClient = blobServiceClient.getContainerClient(container);

  // Get a block blob client
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
  const downloadBlockBlobResponse = await blockBlobClient.download(0);
  return downloadBlockBlobResponse.blobBody;
}

export function getRowsOfCsv(csv) {
  var rows = csv.match(/(?:"(?:[^"]|"")*"|[^,\n]*)(?:,(?:"(?:[^"]|"")*"|[^,\n]*))*\n/g);
  return rows;
}

export function getStyleImportant(element, style, value) {
  if (element) {
    element.style.setProperty(style, value, 'important');  
  }
}

export function isValidFileType(type) {
  let csv = CONSTANTS.FILETYPES.CSV.TYPES;
  let excel = CONSTANTS.FILETYPES.EXCEL.TYPES;
  let fileTypes = csv.concat(excel);
  return (fileTypes.indexOf(type) > -1);
}

export function getTypeFile(type) {
  let csv = CONSTANTS.FILETYPES.CSV.TYPES;
  let excel = CONSTANTS.FILETYPES.EXCEL.TYPES;

  if(csv.indexOf(type) > -1) {
    return CONSTANTS.FILETYPES.CSV.NAME;
  }

  if(excel.indexOf(type) > -1) {
    return CONSTANTS.FILETYPES.EXCEL.NAME;
  }

  return CONSTANTS.FILETYPES.NONE;
}

export function filterdAccountsByUser(data, accountList, role) {
  let accountByUser = role === CONSTANTS.ROLES.COMPANY_ADMIN ? data :
    data.filter(x => accountList.includes(x.accountId));
  return accountByUser;
}

export function filterTemplatesByAccount(templates, accounts, role) {
 
  let templatesByAccount = [];

  if(role === CONSTANTS.ROLES.COMPANY_ADMIN) {
    templatesByAccount = templates;
  } else {
    accounts.forEach(ac => {
      let res = templates.find(x => x.accountId === ac.accountId);
      if(res) {
        templatesByAccount.push(res);
      }
    });
  }

  return templatesByAccount;
}

/*export function isUrlValid(url) {
  // Expresión regular para verificar si la URL comienza con "https://"
  var protocolRegex = /^https:\/\//i;

  // Expresión regular para validar el dominio y la extensión de dominio
  var domainRegex = /^[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  // Verificar que la URL cumple con ambas expresiones regulares
  return protocolRegex.test(url) && domainRegex.test(url.replace(protocolRegex, ''));
}*/

export function isUrlValid(url) {
  // Expresión regular para validar una URL completa
  const urlRegex = new RegExp(
    "^" +
      // protocolo
      "(?:(?:https?|ftp)://)" +
      // dominio
      "(?:\\S+(?::\\S*)?@)?" +
      // IP o nombre de dominio
      "(?:" +
        "(?!-)[A-Za-z0-9-]{1,63}(?:" +
          "\\.(?!-)[A-Za-z0-9-]{1,63}" +
        ")*" +
        // opción de puerto
        "(?::\\d{1,5})?" +
      ")" +
      // ruta
      "(?:/[\\w#!:.?+=&%@!\\-'/]*)?" +
      "$", "i"
  );

  return urlRegex.test(url);
}


/**
 * Masks part of the geo ID with asterisks.
 *
 * @param {string} geoId - The geo ID to be masked.
 * @returns {string} The masked geo ID.
 */
export function maskGeoIdWithAsterisks(geoId) {
  // Check if the geo ID is valid
  if (!geoId || typeof geoId !== 'string' || geoId.length < 8) {
    return geoId; // Return as is if the input is invalid
  }
  
  let visibleStart = geoId.substring(0, 4); // First 4 characters
  let visibleEnd = geoId.substring(geoId.length - 4); // Last 4 characters
  let maskedPart = '*'.repeat(geoId.length - visibleStart.length - visibleEnd.length); // Mask the middle part with asterisks
  return `${visibleStart}${maskedPart}${visibleEnd}`;
}

const encodingSevenBits = [
  ' ',
  '@',
  '£',
  '$',
  '¥',
  '€',
  'è',
  'é',
  'ù',
  'ì',
  'ò',
  'Ç',
  'Ø',
  'ø',
  'Å',
  'å',
  'Δ',
  '_',
  'Φ',
  'Γ',
  'Λ',
  'Ω',
  'Π',
  'Ψ',
  'Σ',
  'Θ',
  'Ξ',
  '^',
  '{',
  '}',
  '[',
  '~',
  ']',
  'Æ',
  'æ',
  'ß',
  'É',
  '!',
  '"',
  '#',
  '¤',
  '%',
  '&',
  '(',
  ')',
  '*',
  '+',
  ',',
  '-',
  '.',
  '/',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '¡',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'Ä',
  'Ö',
  'Ñ',
  'Ü',
  '§',
  '¿',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'ä',
  'ö',
  'ñ',
  'ü',
  'à',
  '\n',
  '\r',
  '\\',
  "'",
  '|'
];

export const cleanErrorMessage = (message) => message.toString().replace(/Error: /g, '');

export default encodingSevenBits;
